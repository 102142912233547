export default class Client {
    constructor() {
        this.urlVersion = '/api/v4';
        this.urlLkDev = 'https://dev.smds.tech';
        this.urlLkProd = 'https://lk.smartdiscount.ru';
    }

    getBaseLkDevRoute() {
        return `${this.urlLkDev}${this.urlVersion}`;
    }

    getBaseLkProdRoute() {
        return `${this.urlLkProd}${this.urlVersion}`;
    }

    getBaseLkRoute() {
        const prodName = 'smartdiscount';

        if (window.location.host.indexOf(prodName) !== -1) {
            return this.getBaseLkProdRoute();
        } else {
            return this.getBaseLkDevRoute();
        }
    }

    sendFeedback = async (data) => {
        return await this.doFetch(
            `${this.getBaseLkRoute()}/feedback`,
            { method: 'POST', body: JSON.stringify(data) }
        );
    }

    logClientError = async (message, level = 'ERROR') => {
        return await this.doFetch(
            `${this.getBaseLkRoute()}/logs`,
            { method: 'POST', body: JSON.stringify({ message, level }) }
        );
    };

    doFetch = async (url, options) => {
        return await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            ...options
        });
    };
}
