import './../sass/main.scss';
console.log('SCRIPTS JS FILE');
//Подключаем SVG спрайты
function requireAll(r) {
    r.keys().forEach(r);
}
requireAll(require.context('./../assets/img/icons-svg', true, /\.svg$/));

fetch(`/assets/img/sprite.svg`)
    .then((res) => {
        return res.text();
    })
    .then((data) => {
        document.getElementById('svg-icons').innerHTML = data;
    });
