const cookie = document.cookie;
const expiresDate = new Date('January 1, 2100 00:00:00');

const cookieStr = {
    accept: "cookieAccept=true",
    referralRef: "referralRef=",
};

// Кука о согласии с куками
let cookieDiv = document.querySelector('.cookie');
let cookieBtn = document.querySelector('.cookie__btn');

cookieBtn.addEventListener('click', () => {
    document.cookie = `${cookieStr.accept}; expires=${expiresDate};`;

    cookieDiv.parentNode.removeChild(cookieDiv);
});

if (cookie.indexOf(cookieStr.accept) !== -1) {
    cookieDiv.parentNode.removeChild(cookieDiv);
} else {
    cookieDiv.classList.add('cookie__show');
}

// Кука с данными о реферальной ссылке
const sourceName = (new URLSearchParams(window.location.search)).get('source');

if (sourceName) {
    const domain = window.location.hostname;

    document.cookie = `${cookieStr.referralRef}${sourceName}; domain=${domain}; expires=${expiresDate};`;
}



