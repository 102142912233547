let regBtn = document.querySelector('.reg-block__btn-reg');

if (regBtn) {
    if (window.innerWidth < 992) {
        regBtn.classList.remove('_revert');
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth < 992 && regBtn.classList.contains('_revert')) {
            regBtn.classList.remove('_revert');
        } else if (window.innerWidth > 991 && !regBtn.classList.contains('_revert')) {
            regBtn.classList.add('_revert');
        }
    });
}
