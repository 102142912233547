import scripts from './scripts';
import modules from './modules/mobile-menu';
import scrollToTop from './modules/scroll-top';
import btnRevert from './modules/btn-revert';
import cookieActions from './modules/cookie-actions';
import feedback from './modules/feedback';
import modalBtnClose from './modules/modal-btn-close';
import footerInstructionLinks from './modules/footer-instructions-links';

import LazyLoad from 'vanilla-lazyload';

let lazyLoadInstance = new LazyLoad({
    use_native: true,
    elements_selector: '.lazy',
});
