
const TOGGLE_BUTTON_SHEVRON_DOWN = 'footer__sub-links-toggle-button-chevron_down';
const LINKS_WRAPPER_SHOW = 'footer__sub-links-wrapper_show';

const toggleBtn = document.querySelector('.footer__sub-links-toggle-button');
const toggleBtnShevron = document.querySelector('.footer__sub-links-toggle-button-chevron');
const linksWrapper = document.querySelector('.footer__sub-links-wrapper')

toggleBtn.addEventListener('click', () => {
    if (toggleBtnShevron.classList.contains(TOGGLE_BUTTON_SHEVRON_DOWN)) {
        const fullPageHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );

        const scrollToPosition = fullPageHeight - window.innerHeight - linksWrapper.offsetHeight;

        window.scrollTo({ top: scrollToPosition });

        toggleBtnShevron.classList.remove(TOGGLE_BUTTON_SHEVRON_DOWN);
        linksWrapper.classList.remove(LINKS_WRAPPER_SHOW);
    } else {
        toggleBtnShevron.classList.add(TOGGLE_BUTTON_SHEVRON_DOWN);
        linksWrapper.classList.add(LINKS_WRAPPER_SHOW);

        window.scrollTo(0, document.body.scrollHeight);
    }
});