let modalBtnClose = document.querySelector('.modal__btn-close');
let modalDiv = document.querySelector('.modal');

const formStr = 'form-';

document.addEventListener('keydown', (evt) => {
    const modalId = document.querySelector('.modal-active');

    if (evt.key === 'Escape' && modalId) {
        closeModal();
    }
});

modalBtnClose.addEventListener('click', () => {
    closeModal();
});

const closeModal = () => {
    const modalId = document.querySelector('.modal-active').id;

    // Сброс значений формы
    if (modalId.includes(formStr)) {
        const formName = modalId.split(formStr)[1];

        removeError(formName);

        document.forms[formName].reset();
    }

    modalDiv.classList.remove('modal-active');
};

const removeError = (formName) => {
    if (formName === 'feedback') {
        let divResponseError = document.querySelector('#feedback-response-error');
        let divPhoneError = document.querySelector('#feedback-phone-error');
        let inputFeedbackPhone = document.querySelector('#feedbackPhone');
        let feedbackSuccessMsg = document.querySelector('#feedback-success-msg');
        let formFeedback = document.forms.feedback;

        if (divResponseError.classList.contains('_show')) {
            divResponseError.classList.remove('_show');
        }
        if (divPhoneError.classList.contains('_show')) {
            divPhoneError.classList.remove('_show');
        }
        if (inputFeedbackPhone.classList.contains('input__error')) {
            inputFeedbackPhone.classList.remove('input__error');
        }
        if (feedbackSuccessMsg.classList.contains('_show')) {
            feedbackSuccessMsg.classList.remove('_show');
            formFeedback.classList.remove('d-none');
        }
    }
};
