let btnScrollTop = document.querySelector('.btn-up');

document.addEventListener("scroll", (e) => {
    if (window.pageYOffset >= 1200) {
        btnScrollTop.classList.add('_active');
    } else {
        btnScrollTop.classList.remove('_active');
    }
});

btnScrollTop.addEventListener('click', function (e) {
    window.scrollTo(0, 0);
});
